// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'

/**
 * Used to trigger globe interaction recalculation when the globe appears in the viewport, thus eliminating the offset
 * that only occurs on homepage. This is probably due the tiny shift in the page layout when the globe is loaded.
 */
const globeObserver = new IntersectionObserver(
  (entries: IntersectionObserverEntry[]) => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        const globeCanvas = document.querySelector('.js-globe-root canvas') as HTMLCanvasElement
        globeCanvas?.dispatchEvent(new CustomEvent('globeTriggerResize'))
        globeObserver.disconnect()
      }
    }
  },
  {
    rootMargin: `0% 0% 0% 0%`,
    threshold: 0,
  },
)

observe('.js-webgl-globe-trigger', element => {
  globeObserver.observe(element)
})

function reducedMotionHandler(element: HTMLElement) {
  const globeRootContainer = element.closest('.js-globe-root')
  globeRootContainer?.querySelector('canvas')?.dispatchEvent(new CustomEvent('globeTriggerFreeze'))
  const videos = globeRootContainer?.querySelectorAll('video')

  if (!videos) return
  for (const video of videos) {
    if (!video.paused) video.pause()
  }
}

document.addEventListener('globeLoaded', ((e: CustomEvent) => {
  const globeContainer = e.detail.globeContainer as HTMLElement
  if (!globeContainer) return
  if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) reducedMotionHandler(globeContainer)
  const button = globeContainer.closest('.js-globe-root')?.querySelector('.js-globe-pause-toggle')
  if (!button) return
  const globeCanvas = globeContainer.querySelector('canvas') as HTMLCanvasElement
  const videos = globeContainer.querySelectorAll('video')
  let paused = false

  button.addEventListener('click', () => {
    paused = !paused
    const event = paused ? 'globeTriggerFreeze' : 'globeTriggerUnfreeze'
    globeCanvas.dispatchEvent(new CustomEvent(event))

    for (const video of videos) {
      if (paused) {
        if (!video.paused) video.pause()
      } else {
        if (video.paused) video.play()
      }
    }
  })
}) as EventListener)

document.addEventListener('globeFallbackImage', ((e: CustomEvent) => {
  const globeContainer = e.detail.globeContainer as HTMLElement
  if (!globeContainer) return
  const button = globeContainer.closest('.js-globe-root')?.querySelector('.js-globe-pause-toggle')
  if (button) button.remove()
}) as EventListener)

observe('.js-globe-pause-toggle', element => {
  if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) reducedMotionHandler(element as HTMLElement)
})
